@tailwind base;
@tailwind components;
@tailwind utilities;

main{
    margin-bottom:80px;
}

@keyframes subtle-ping {
    0%, 100% {
      transform: scale(1);
      opacity: 0.8;
    }
    50% {
      transform: scale(1.05);
      opacity: 1;
    }
  }
  
  /* Utility class for the animation */
  .animate-subtle-ping {
    animation: subtle-ping 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  